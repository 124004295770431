<template>
 <v-container>
    <v-row class=" mt-4 justify-center ">
      <v-col cols="12" md="5" xm="12">

  		 <v-snackbar
        v-model="snackbar"
        :timeout="4000"
        top
        color="blue-grey darken-1"
        >
        Lo sentimos no se pudo activar esté usuario.
        <v-btn
          color="white"
          text
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>

    		<v-card  align="center" class="contenido elevation-8 " >
    		  <span > El usuario ha sido activado </span>

          <v-divider class="mx-1 mt-3"></v-divider>

          <v-btn   color="primary " class="mt-2" :to="{name: 'login' }">Iniciar Sesión</v-btn>

    		  <v-divider class="mx-1 mt-2"></v-divider>
          <v-card-actions align="center">
            <img class="sait" :src="logo"  height="100%" width="100%"/>
          </v-card-actions>

    		</v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      snackbar: false,
      id: '',
      logo: ''
    }
  },

  created () {
    this.$http.get('api/v1/logos.list').then(response => {
      this.logo = response.body.Logourl
    }, error => {
      console.log(error)
    })
    this.id = this.$route.params.id
    this.activarcorreo()
  },
  methods: {
     	activarcorreo (id) {
      var me = this

     		this.$http.get('api/v1/activarusuario/' + this.id).then(function (response) {
     			if (response.body == null) {
     				this.snackbar = true
     			} else {

     			}
     		})
     	}

  }
}
</script>

<style scoped>
.contenido{
	padding: 48px;
}

.titulo{
	font-family: black;
	font-size: 30px;
}

.sait{
	font-family: black;
	font-size: 18px
}

.infoEmpresa{
  font-size: 11px
}
</style>
